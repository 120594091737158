import { TechnicalError } from '@orus.eu/error'
import { ContentContainerAppClient, PageTitle, useTranslate } from '@orus.eu/pharaoh'
import { Navigate, useSearch } from '@tanstack/react-router'
import type { ReactElement } from 'react'
import { useNavigateBack } from '../../lib/hooks/use-navigate-back'
import { GlobalLoadingState } from '../molecules/global-loading-state'
import { UpdatePaymentForm } from '../organisms/payment-form/update-payment-form'

export default function PaymentMethodNewPage(): ReactElement {
  const translate = useTranslate()
  const navigateBack = useNavigateBack()
  const searchParams = useSearch({ strict: false })

  const redirectStatus = searchParams.redirect_status
  const shouldRedirectToInvoices = redirectStatus === 'succeeded'

  if (redirectStatus && redirectStatus !== 'succeeded') {
    throw new TechnicalError('Todo : handle non success stripe return codes when updating payment method', {
      context: { redirectStatus },
    })
  }

  if (shouldRedirectToInvoices) return <Navigate to="/invoices" replace />

  return (
    <>
      <PageTitle title={translate('payment_method_update_page_title')} />
      <ContentContainerAppClient>
        {shouldRedirectToInvoices ? (
          <GlobalLoadingState />
        ) : (
          <UpdatePaymentForm sessionLessAuth={undefined} navigateBack={navigateBack} backButton />
        )}
      </ContentContainerAppClient>
    </>
  )
}
